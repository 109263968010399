import React from 'react';

const WelcomeText = () => {
  return (
    <div className="slider-intro">
      <h1 className="slider-title">
        Hi, I'm Mayuresh, a <span>Senior UI Developer</span>.
      </h1>
      <p className="slider-text" role="heading" aria-level="2">
        I've always held a deep love for coding, and my passion lies in creating
        user interfaces that marry beauty with usability, enhancing engagement.
      </p>
    </div>
  );
};

export default WelcomeText;
