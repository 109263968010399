import React from 'react';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';

const SwiperCustomNavigation = ({
  onPrevClick,
  onNextClick,
  prevDisabled,
  nextDisabled,
}) => {
  return (
    <div className="custom-swiper-navigation">
      <div
        className={`swiper-button swiper-button-prev ${
          prevDisabled ? 'swiper-button-disabled' : ''
        }`}
        aria-label="Click to view previous slide"
        role="button"
        onClick={onPrevClick}
      >
        <GoArrowLeft className="svg-icon" />
      </div>
      <div
        className={`swiper-button swiper-button-next ${
          nextDisabled ? 'swiper-button-disabled' : ''
        }`}
        aria-label="Click to view next slide"
        role="button"
        onClick={onNextClick}
      >
        <GoArrowRight className="svg-icon" />
      </div>
    </div>
  );
};

export default SwiperCustomNavigation;
