import React from 'react';

const ProjectDetails = ({ dateCategory, title, description }) => {
  return (
    <div className="project-details">
      <div className="project-date-category">{dateCategory}</div>
      <h2 className="project-title">{title}</h2>
      <p className="project-description">{description}</p>
    </div>
  );
};

export default ProjectDetails;
