import React from 'react';

import ProfilePicture from './ProfilePicture';
import profilePic320 from '../images/mayuresh-daphane-profile-picture-320.webp';
import profilePic768 from '../images/mayuresh-daphane-profile-picture-768.webp';

import WelcomeText from './WelcomeText';
import WorkDownArrow from './WorkDownArrow';

const Slider = () => {
  return (
    <div className="site-slider">
      <div className="content-wrapper">
        <div className="site-slider-wrapper">
          <ProfilePicture
            profilePic320={profilePic320}
            profilePic768={profilePic768}
            altText="Mayuresh Daphane's Profile Picture"
          />
          <WelcomeText />
          <WorkDownArrow />
        </div>
      </div>
    </div>
  );
};

export default Slider;
