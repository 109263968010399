import React from 'react';
import { AUTHOR_NAME } from '../constants';

const Logo = () => {
  return (
    <div className="logo-container">
      <a
        href={`${process.env.PUBLIC_URL}/`}
        className="logo-link"
        title="Home"
        aria-label="Mayuresh Daphane's logo"
      >
        <span className="logo-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
          >
            <rect
              width="40"
              height="40"
              fill="rgba(208, 216, 228, 0.2)"
              rx="5"
            />
            <text
              x="50%"
              y="50%"
              fill="#8632f0"
              textAnchor="middle"
              transform="translate(0, 6)"
            >
              &lt;/&gt;
            </text>
          </svg>
        </span>
        <span className="logo-text">{AUTHOR_NAME}</span>
      </a>
    </div>
  );
};

export default Logo;
