import React from 'react';

const ProfilePicture = ({ profilePic320, profilePic768, altText }) => {
  return (
    <div className="profile-picture">
      <picture>
        <source media="(min-width: 768px)" srcSet={profilePic768} />
        <img
          className="img"
          src={profilePic320}
          alt={altText}
          width="100"
          height="100"
        />
      </picture>
    </div>
  );
};

export default ProfilePicture;
