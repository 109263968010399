import React from 'react';
import Logo from './Logo';
import NavMenu from './NavMenu';

const Header = () => {
  return (
    <header id="site-header" className="site-header">
      <div className="content-wrapper">
        <Logo />
        <NavMenu />
      </div>
    </header>
  );
};

export default Header;
