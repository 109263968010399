import React, { useState } from 'react';
import { pageLinks } from '../data';
import HamburgerMenu from './HamburgerMenu';

const NavMenu = () => {
  // State to manage the menu open/close state
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to toggle the menu state
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // Function to close the menu when a link is clicked
  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div className="navigation-container">
      <HamburgerMenu onMenuToggle={toggleMenu} isOpen={menuOpen} />
      <div className={`overlay ${menuOpen ? 'open' : ''}`}>
        <nav className="nav-menu">
          <ul className="nav-menu-list">
            {pageLinks.map((item) => {
              return (
                <li key={item.id} className="nav-menu-item">
                  {item.type === 'link' ? (
                    <a
                      className="nav-menu-link"
                      href={item.href}
                      aria-label={`Go to the ${item.text.toLowerCase()} section`}
                      role="button"
                      onClick={closeMenu} // Close menu on link click
                    >
                      {item.text}
                    </a>
                  ) : item.type === 'social' ? (
                    <a
                      className="nav-menu-link"
                      href={item.href}
                      target="_blank"
                      aria-label={item.ariaLabel}
                      role="button"
                      rel="noreferrer"
                      onClick={closeMenu} // Close menu on link click
                    >
                      {item.icon}
                    </a>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavMenu;
