import React from 'react';

const SwiperSlideComponent = ({
  description,
  link,
  ariaLabel,
  linkText,
  member,
  designation,
}) => {
  return (
    <div className="swiper-slide-item">
      <p className="feedback">
        {description}
        <a href={link} target="_blank" aria-label={ariaLabel} rel="noreferrer">
          {linkText}
        </a>
      </p>
      <strong>{member}</strong>
      <p className="designation">{designation}</p>
    </div>
  );
};

export default SwiperSlideComponent;
