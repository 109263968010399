import React from 'react';
import { FaRegCopyright } from 'react-icons/fa6';
import { CURRENT_YEAR, AUTHOR_NAME } from '../constants';

const Footer = () => {
  return (
    <footer id="contact" className="site-footer">
      <div className="content-wrapper">
        <div className="site-footer-wrapper top">
          <h4>Connect with me</h4>
          <nav className="nav-menu">
            <ul className="nav-menu-list">
              <li className="nav-menu-item">
                <a
                  className="nav-menu-link"
                  href="mailto:mayureshdaphane@gmail.com"
                  aria-label="mayureshdaphane@gmail.com"
                >
                  mayureshdaphane@gmail.com
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-footer-wrapper bottom">
          <FaRegCopyright className="svg-icon copyright-icon" />
          <p className="copyright">
            {CURRENT_YEAR} {AUTHOR_NAME}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
