import React from 'react';

const ProjectImage = ({
  imageClassName,
  image768,
  image320,
  altText,
  width = '320',
  height = '240',
}) => {
  return (
    <div className={`project-image ${imageClassName}`}>
      <picture>
        <source media="(min-width: 768px)" srcSet={image768} />
        <img
          className="img"
          src={image320}
          alt={altText}
          width={width}
          height={height}
        />
      </picture>
    </div>
  );
};

export default ProjectImage;
