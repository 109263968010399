import React from 'react';
import { RiDoubleQuotesL } from 'react-icons/ri';
import SwiperMain from './SwiperMain';

const Mentions = () => {
  return (
    <div id="mentions" className="site-mentions">
      <div className="content-wrapper">
        <div className="mentions-wrapper">
          <h3>Mentions</h3>
          <RiDoubleQuotesL className="svg-icon quote-icon" />
          <div className="swiper-container">
            <SwiperMain />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentions;
