import { FaLinkedin } from 'react-icons/fa';

export const pageLinks = [
  {
    id: 1,
    href: '#work',
    text: 'Work',
    ariaLabel: 'Go to the work section',
    type: 'link',
  },
  {
    id: 2,
    href: '#mentions',
    text: 'Mentions',
    ariaLabel: 'Go to the mentions section',
    type: 'link',
  },
  {
    id: 3,
    href: '#contact',
    text: 'Contact',
    ariaLabel: 'Go to the contact section',
    type: 'link',
  },
  {
    id: 4,
    href: 'https://www.linkedin.com/in/mayureshdaphane/',
    text: 'LinkedIn',
    ariaLabel: 'Link to my LinkedIn profile',
    type: 'social',
    icon: <FaLinkedin className="svg-icon" />,
  },
];
