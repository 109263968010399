import React from 'react';

const HamburgerMenu = ({ onMenuToggle, isOpen }) => {
  return (
    <button
      className={`hamburger-menu ${isOpen ? 'is-active' : ''}`}
      aria-label="Primary Menu"
      onClick={onMenuToggle}
    >
      <div className="menu-icon">
        {[...Array(3)].map((_, index) => (
          <span key={index} className="line"></span>
        ))}
      </div>
    </button>
  );
};

export default HamburgerMenu;
