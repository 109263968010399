import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperSlideComponent from './SwiperSlideComponent';
import SwiperCustomNavigation from './SwiperCustomNavigation';

const slidesData = [
  {
    description: `I have enjoyed working alongside Mayuresh for nearly 4 years
                  now, and I cannot recommend him highly enough. Mayuresh is a
                  hardworking professional who consistently delivers the highest
                  quality work. Mayuresh thrives on challenges and is eager to
                  push his skillset to new heights.`,
    link: 'https://www.linkedin.com/in/mayureshdaphane/',
    ariaLabel: 'Original Source: LinkedIn',
    linkText: 'Original Source: LinkedIn',
    member: 'Ivor Anderson',
    designation: 'Graphic Designer',
  },
  {
    description: `I have been working directly with Mayuresh for over a year now
                  and have watched him grow both professional and personally.
                  Mayuresh is not afraid of a challenge and to push the
                  boundaries of what he is comfortable with, all with the
                  knowledge that it will make him better.`,
    link: 'https://www.linkedin.com/in/mayureshdaphane/',
    ariaLabel: 'Original Source: LinkedIn',
    linkText: 'Original Source: LinkedIn',
    member: 'Alex Bowen',
    designation: 'Technical Program Manager',
  },
  {
    description: `Mayuresh is a hard working person, always keen on learning and
                  improving each day. If you give him a task, you can rest
                  assured that the job will be done perfectly. He communicates
                  well and never shies away from asking questions. Always a
                  pleasure to have someone like him in my team.`,
    link: 'https://www.linkedin.com/in/mayureshdaphane/',
    ariaLabel: 'Original Source: LinkedIn',
    linkText: 'Original Source: LinkedIn',
    member: 'Geku Maniyara',
    designation: 'Senior Engineering Manager',
  },
];

const SwiperMain = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    const swiper = swiperRef.current;

    if (swiper) {
      const updateDisabledClasses = () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      };

      // Update classes on initial render and every slide change
      updateDisabledClasses();
      swiper.on('slideChange', updateDisabledClasses);

      return () => {
        swiper.off('slideChange', updateDisabledClasses);
      };
    }
  }, []);

  return (
    <div className="swiper-main-wrapper">
      <Swiper
        speed={500}
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 2,
          },
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <SwiperSlideComponent
              description={slide.description}
              link={slide.link}
              ariaLabel={slide.ariaLabel}
              linkText={slide.linkText}
              member={slide.member}
              designation={slide.designation}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperCustomNavigation
        onPrevClick={() => swiperRef.current?.slidePrev()}
        onNextClick={() => swiperRef.current?.slideNext()}
        prevDisabled={isBeginning}
        nextDisabled={isEnd}
      />
    </div>
  );
};

export default SwiperMain;
