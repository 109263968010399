import React from 'react';

import ProjectDetails from './ProjectDetails';
import ProjectImage from './ProjectImage';

const Item = ({
  itemClassName,
  dateCategory,
  title,
  description,
  imageClassName,
  image768,
  image320,
  altText,
}) => {
  return (
    <div className={`item ${itemClassName}`}>
      <ProjectDetails
        dateCategory={dateCategory}
        title={title}
        description={description}
      />
      <ProjectImage
        imageClassName={imageClassName}
        image768={image768}
        image320={image320}
        altText={altText}
      />
    </div>
  );
};

export default Item;
