import React from 'react';
import { GoArrowDown } from 'react-icons/go';
import { pageLinks } from '../data';

const WorkDownArrow = () => {
  const workLink = pageLinks.find((item) => item.id === 1);
  return (
    <a
      className="scroll-to-section"
      href={workLink.href}
      title={workLink.text}
      aria-label={workLink.ariaLabel}
      role="button"
    >
      {workLink.text}
      <GoArrowDown className="svg-icon" />
    </a>
  );
};

export default WorkDownArrow;
